.error-validations {
  width: 100%;
  margin-top: 0.25rem;
  color: #dc3545;
  display: none;

  &.error-enabled {
    display: block;
  }
}
