.container-loader {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1051;
  background-color: rgba(0, 0, 0, 0.59);
  justify-content: center;
  align-items: center;
}
