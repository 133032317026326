.sign-in {
    .content {
        height: 100vh;
        background-image: url("../../assets/img/jpg/bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;

        .sign-in-box {
            background-color: #FFF;
            padding: 20px;
            border-radius: 5px;
            max-width: 400px;
            font-size: .8rem;
        }
    }
}