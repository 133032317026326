// Class Colors

.color-black {
    color: #000;
}

.color-white {
    color: #fff;
}

.bg-color-white {
    background-color: #fff;
}

.bg-color-grey {
    background-color: #f8faff;
}

// Sizes
.size-05 {
    font-size: 0.5em;
}
.size-06 {
    font-size: 0.6em;
}
.size-07 {
    font-size: 0.7em;
}
.size-08 {
    font-size: 0.8em;
}
.size-09 {
    font-size: 0.9em;
}
.size-11 {
    font-size: 1.1em;
}
.size-12 {
    font-size: 1.2em;
}
.size-13 {
    font-size: 1.3em;
}
.size-14 {
    font-size: 1.4em;
}
.size-15 {
    font-size: 1.5em;
}

// Sizes
.rounded-10 {
    border-radius: 5px;
}
.rounded-15 {
    border-radius: 15px;
}
.rounded-20 {
    border-radius: 20px;
}
.rounded-25 {
    border-radius: 25px;
}
.rounded-30 {
    border-radius: 30px;
}
.rounded-35 {
    border-radius: 35px;
}
.rounded-40 {
    border-radius: 40px;
}

// Rounded Top
.rounded-top-10 {
    border-radius: 5px 5px 0 0;
}
.rounded-top-15 {
    border-radius: 15px 15px 0 0;
}
.rounded-top-20 {
    border-radius: 20px 20px 0 0;
}
.rounded-top-25 {
    border-radius: 25px 25px 0 0;
}
.rounded-top-30 {
    border-radius: 30px 30px 0 0;
}
.rounded-top-35 {
    border-radius: 35px 35px 0 0;
}
.rounded-top-40 {
    border-radius: 40px 40px 0 0;
}

// Shadows

.bottom-shadow {
    box-shadow: 10px 10px 10px #f0f0f08f;
}

// Images

.img-default {
    max-width: 100%;
}

// Transformations

.bold {
    font-weight: bold;
}

.bold-300 {
    font-weight: 500;
}
// Icons

.line-border {
    border-bottom: 1px solid #e3e3e3;
}

.underline {
    text-decoration: underline;
}

.border-default {
    border: 1px solid map-get($map: $colors, $key: "border");
}

.margin-bottom-payment {
    margin-bottom: 40px;
}

.margin-bottom-auth {
    margin-bottom: 80px;
}

.placeholder {
    &::placeholder {
        font-size: 1rem;
    }
}
.placeholder-11 {
    &::placeholder {
        font-size: 1.1rem;
    }
}
.placeholder-12 {
    &::placeholder {
        font-size: 1.2rem;
    }
}
.placeholder-13 {
    &::placeholder {
        font-size: 1.3rem;
    }
}
.placeholder-14 {
    &::placeholder {
        font-size: 1.4rem;
    }
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.modal-400 {
    max-width: 400px;

    @media (max-width: 500px) {
        max-width: 90%;
    }
}

.capitalize {
    text-transform: capitalize;
}
