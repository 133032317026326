.default-template-menu {
    width: 280px !important;
    color: rgb(110, 117, 159);
    background: rgb(255, 255, 255);
    box-shadow: rgb(159 162 191 / 18%) 2px 0px 3px, rgb(159 162 191 / 32%) 1px 0px 1px;
    min-height: 100vh;
    z-index: 10;

    @media(max-width: 750px) {
        display: none;
    }

    .default-template-logo {
        height: 88px;
        border-bottom: 1px solid rgb(242, 245, 249);
    }

    .default-template-menu-item {
        button {
            font-size: 0.9rem;
            background-color: transparent;
            border: none;
            border-radius: 8px;

            span {
                color: rgb(36, 46, 111);
            }

            &:hover {
                background-color: #f2f5f9;
                color: #929292;
                span {
                    color: #929292;
                }
            }
        }

        &.selected {
            button {
                font-size: 0.9rem;
                background-color: #f2f5f9;
                color: #929292;
                border: none;
                border-radius: 8px;

                span {
                    color: #929292;
                }
            }
        }
    }
}

.default-template-header {
    height: 88px;
    color: rgb(110, 117, 159);
    padding: 0px 16px;
    z-index: 6;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(159 162 191 / 18%) 0px 2px 3px, rgb(159 162 191 / 32%) 0px 1px 1px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% - 280px);

    .people-icon {
        font-size: 1.5rem;
        @media(min-width: 750px) {
            font-size: 2rem;
        }
    }

    @media(max-width: 750px) {
        width: 100%;
        justify-content: space-between;
    }
}

.default-template-box {
    background-color: rgb(255, 255, 255);
    color: rgb(34, 51, 84);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 6px;
    box-shadow: rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px;
}

.default-template-content {
    background-color: #f2f5f9;
}

.with-header {
    margin-top: 88px !important;
}
